//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Home from '../view/home/index.vue';
import PrivacyPolicy from "../view/privacypolicy/index.vue"
import ResponsibleGameplayPolicy from "../view/responsiblegameplaypolicy/index.vue"
import SweepsRules from "../view/sweepsrules/index.vue"
import GameStatement from "../view/gamestatement/index.vue"
import TermsOfService from "../view/termsandconditions/index.vue"


const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: Home,
        },
        {
            name:'privacy',
            path: '/privacy.html',
            component: PrivacyPolicy,
        },
        {
            name:'termsofservice',
            path: '/termsofservice.html',
            component: TermsOfService,
        },

        {
            name:'viphome',
            path: '/vip/home',
            component: Home,
        },
        {
            name:'vipprivacy',
            path: '/vip/privacy.html',
            component: PrivacyPolicy,
        },{
            name:'viptermsofservice',
            path: '/vip/termsofservice.html',
            component: TermsOfService,
        },{
            name:'viprgp',
            path: '/vip/respongamepolicy.html',
            component: ResponsibleGameplayPolicy,
        },{
            name:'vipgamestatement',
            path: '/vip/gamestate.html',
            component: GameStatement,
        },{
            name:'sweepsru',
            path: '/vip/sweepsrules.html',
            component: SweepsRules,
        },
        {
            path: '/vip',
            redirect: '/vip/home',
        },
        {
            path: '/*',
            redirect: '/home',
        }
    ]
  })


export default router
