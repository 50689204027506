<template>
  <div class="box">
    <img class="box_logo" src="../../assets/image/logo-200x50.png" alt="">
    <div class="container">
      <h1>GAME STATEMENT</h1>
      <p class="up_date" style="font-size: 14px;"><strong>DATED: 8th April 2024</strong></p>
      <p class="up_tit">This document is a game statement provided by the Santa Jacinta team (collectively referred to as "the team," "we," "us," or "ourselves" herein).</p>
      <p class="statement">This statement encompasses all games and services offered by our gaming team. The main focus of this statement is to outline where we offer our games and who is eligible to play them. It is essential that you understand the content of this statement. If you do not agree with the terms laid out in this statement, please refrain from using our game or any of our services.</p>
      <ul class="ul_1">
        <ul class="ul_2">
          <ul class="ul_3">
            <li>
              <strong style="line-height: 40px;">Who can play the game?</strong>
              <ul style="list-style-type: none;">
                <li>In the United States and Canada:All players must be at least 18 years of age and citizens of either the United States or Canada.</li>
              </ul>
            </li>
            <li>
              <strong style="line-height: 40px;">Where We Offer Cash Prize Competitions?</strong>
              <ul style="list-style: none;">
                <li>
                  <p><strong>In the United States and Canada:</strong></p>
                  <p>We provide our games and services exclusively within the United States and Canada.</p>
                  <p>We do not offer games in the following restricted states: </p>
                  <p>(1) Washington</p>
                  <p>(2) Idaho</p>
                  <p>We do not offer games in the following restricted province:</p>
                  <p>(1) Quebec</p>
                  <p>All other states are considered legal for gameplay.</p>
                  <p>We utilize location systems from Apple or Android to determine a player's eligibility to play based on the GPS service of the player's mobile device.</p>
                  <p>However, we cannot guarantee that all users will be accurately identified by these systems. It is your responsibility to ensure full compliance with all applicable state laws when utilizing our games or services.</p>
                </li>
              </ul>
            </li>
          </ul>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GAMESTATEMENT'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
}

.container {
  width: 7.2917rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: upper-alpha;
  margin-left: -1em;
  line-height: 35px;
  font-size: 20px;
  .ul_2 {
    font-size: 14px;
    list-style-type: lower-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: 14px;
      list-style-type: disc;
      text-align: left;
      line-height: 24px;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.up_tit{
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 50px;
}
.statement{
  font-size: 18px;
  line-height: 30px;
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
    .up_tit{
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
  .ul_1 {
    font-size: .0521rem;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>