<template>
  <div class="box">
    <img class="box_logo" src="../../assets/image/logo-200x50.png" alt="">
    <div class="container">
      <h1>Responsible for game policy</h1>
      <p class="up_date" style="font-size: 14px;"><strong>DATED: 8th April 2024</strong></p>
      <span class="tit">Santa Jacinta is committed to the protection of our players and promoting responsible social gameplay as a policy of customer care and social responsibility.</span>
      <span class="tit">We believe it is our shared responsibility with you, our customers, to ensure that you enjoy your experience on our Platform while remaining aware of the potential risks that can be associated with online gameplay if you don’t remain in control. We encourage you to use the responsible social gameplay tools described below available at your disposal.</span>
      <span class="tit">In order to ensure you enjoy the fun and affordable game, we fully support responsible social games, and have taken steps to help players want to control the game. We reserve the right to activate such measures unilaterally if we deem them necessary.</span>
      <ul class="ul_1">
        <li>
          <p><strong>SUGGESTION</strong></p>
          <ul>
            <li>(a) to avoid when depressed or emotional game.</li>
            <li>(b) Take frequent breaks during the game.</li>
            <li>(c) Avoid playing games while drunk.</li>
            <li>(d) Avoidance of redemption.</li>
            <li>(e) Remember that gameplay is only a form of entertainment and should not be seen as a source of income or a means of escapism.</li>
            <li>(f) Only buy with what you can afford.</li>
            <li>(g) Set a budget and don't exceed it.</li>
            <li>(h) Set a time limit before playing</li>
            <li>(i) Understand how the game works before you play it, and remember that the outcome is random.</li>
            <li>(j) Never let gameplay interfere with your work, relationships, health, or commitments.</li>
          </ul>
        </li>
        <li>
          <strong>PROTECTION</strong>
          <ul>
            <li>(a) Make sure that the decision to play on our Platform is your own personal choice and responsibility.</li>
            <li>
              (b) We do not recommend playing on our Platform if you:<br>
              <ul>
                <li>(i) are being treated or are in recovery for an addiction/dependency</li>
                <li>(ii) are under the influence of alcohol or any other substance</li>
                <li>(iii) are currently experiencing financial difficulty or a traumatic life event</li>
                <li>(iv) do not understand how to play the Games</li>
                <li>(v) have any mental health concerns, cognitive impairment or brain injury.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>INTRODUCTION</strong>
          <span class="tit">This Responsible Gameplay Policy (RG Policy) describes the control tools, information and resources available to registered players on Game.</span>
          <span class="tit">This RG Policy forms part of the Game Terms and Conditions. Terms which are defined in the Terms and Conditions have the same meaning in this RG Policy.</span>
          <span class="tit">We may update the RG Policy at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately. </span>
          <span class="tit">Whenever we amend this RG Policy in a way that would limit your current rights or which may be detrimental, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended RG Policy, you must stop using the Platform. </span>
        </li>
        <li>
          <strong>Game GRG Program</strong>
          <span class="tit">The Game Responsible Gameplay Program (GRG Program) is centered around our guiding principles of providing our customers with control tools,<br> information and help resources needed to: </span>
          <span class="tit">Make an informed decision in gameplay <br>Prevent problem gameplay from occurring on our site.</span>
          <span class="tit">The GRG Program is designed to support the needs of individuals at any stage of the customer journey and any level of gameplay. To do this, the GRG Program offers a range of player education, control tools and resources for professional help when needed. </span>
          <span class="tit">We also understands that it is a shared responsibility to achieve a fun and affordable gameplay environment and that it is ultimately an individual ’s choice to play. We do not provide counseling services nor do we police customer behavior. Instead, we focus on providing control tools and informing, educating and supporting informed decisions.</span>
          <span class="tit">We have well-trained staff available to assist you in relation to your gameplay. Our staff are encouraged and empowered to provide information and offer control tools proactively. </span>
        </li>

        <li>
          <strong>RSG PLAY CONTROL TOOLS</strong>
          <span class="tit">
            Activity Reminders<br>
            Game assists your play by providing an Activity Reminder every hour.<br>
            The Activity Reminder:
          </span>
          <ul>
            <li>(i) suspends play and indicates how long you have been playing </li>
            <li>(ii) displays your play history since logging in</li>
            <li>(iii) allows you to end the gameplay session or continue playing.</li>
          </ul>
          <span class="tit">
            Account History <br>
            Gameplay History Shows the result of each spin/hand played.
          </span>
          <span class="tit">Transaction History Shows your purchase and redemption history. </span>
        </li>
        <li>
          <strong>SUPPORT ORGANISATIONS</strong>
          <span class="tit">If your gameplay may have had, or is at risk of having, a negative impact on your mental health, finances or relationships with friends or family, we encourage you to get in touch with the following help and support organizations:</span>
          <ul>
            <li>(a) Counseling Gaming Addicts Anonymous (GAA) is a fellowship of people who support each other in recovering from the problems resulting from excessive game playing.</li>
            <li>
              (b) Credit Counseling
              <ul>
                <li>(i) Financial Counseling Association of America (FCAA) is a professional association of financial counseling agencies that assist consumers with financial counseling services of all kinds, as well as debt management plans for the repayment of unsecured debts. </li>
                <li>(ii) National Foundation for Credit Counseling (NFCC) is one of the oldest networks of non-profit financial counseling agencies. The NFCC helps people to defeat their debt and look forward with confidence.</li>
              </ul>
            </li>
            <li>(c) Please note that these organizations are independent support services and are NOT in any way affiliated with Game. They do NOT provide customer support or dispute resolution services.</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RESPONSIBLEGAMEPLAYPOLICY'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
}

.container {
  width: 7.2917rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: none;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: 20px;
  ul {
    font-size: 14px;
    list-style-type: none;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
  }
}
.ul_3{
  list-style-type: disc !important;
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: 16px;
    ul{
      line-height: 30px;
      padding-left: .0521rem;
    }
  }
}
</style>