<template>
    <div class="container">
        <!-- 顶部导航栏 -->
        <div class="top_nav">
            <div class="top_nav_left">
                <img class="top_nav_left_img" src="../../assets/image/logo-200x50.png" alt="">
            </div>
            <div class="top_nav_right">
                <ul class="top_nav_ul">
                    <li><a id="home" href="#home" style="text-decoration: none;" >home</a></li>
                    <li><a id="about" href="#about" style="text-decoration: none;" @click="scroll('about')">about</a></li>
                    <li><a id="story" href="#story" style="text-decoration: none;" @click="scroll('story')">story</a></li>
                    <li><a id="games" href="#games" style="text-decoration: none;" @click="scroll('games')">games</a></li>
                    <li><a id="contact" href="#contact" style="text-decoration: none;" @click="scroll('contact')">contact us</a></li>
                    <!-- <li><router-link style="text-decoration: none;" to="/careersHome/">careers</router-link></li> -->
                    <!-- <li><a id="news" href="#news" style="text-decoration: none;" @click="scroll('news')">news</a></li> -->
                </ul>
            </div>
        </div>

        <div class="home" id="home2">
            <img class="home_img" src="../../assets/image/PlayRoom.png" alt="">
            <div class="home_text_box">
                <span class="home_text">{{ home_text }}</span>
            </div>
        </div>

        <div class="about" id="about2">
            <img class="about_left_img" src="../../assets/image/about.png" alt="">
            <div class="about_right">
                <div class="about_text_tit">About<span style="color: #1997ed;">·</span><span style="color: #0ecaa6;">·</span><span style="color: #fe8f2f;">·</span></div>
                <div class="about_text">{{ about_text }}</div>
            </div>
        </div>

        <div class="story">
            <img class="story_up_img" src="../../assets/image/about_story.png" alt="">
            <div class="story_body">
                <div class="story_left" id="story2">
                    <div class="story_text_tit">Story<span style="color: #1997ed;">·</span><span style="color: #0ecaa6;">·</span><span style="color: #fe8f2f;">·</span></div>
                    <div class="story_text">{{ story_text }}</div>
                </div>
                <img class="story_right_img" src="../../assets/image/story-1.png" alt="">
            </div>
        </div>

        <img class="the_team_up_img" src="../../assets/image/story-2.png" alt="">
        
        
        <div class="games" id="games2">
            <div class="games_tit">games<span style="color: #1997ed;">·</span><span style="color: #0ecaa6;">·</span><span style="color: #fe8f2f;">·</span></div>
            <div class="games_show">
                <div class="game_show">
                    <div class="game">
                        <img src="../../assets/image/game1_Logo.png" class="game_img">
                        <div class="game_text">
                            {{ game1_text }}
                        </div>
                    </div>
                    <div class="img-edge-cover">
                        <img class="game_bottom_img" src="../../assets/image/game1.png" alt="">
                    </div>
                </div>
                
                <div class="game_show gamebgcolor4">
                    <div class="game" style="display: block; text-align: center;">
                        <h1>Coming Soon</h1>
                        <div class="game_text">
                            As Santa Jacinta, we're channeling our past successes and lessons learned into the creation of our most ambitious and exhilarating games yet. While some will hit the shelves before others, we're eager to unveil each one to our players and share the excitement we've poured into them!
                        </div>
                    </div>
                    <div class="img-edge-cover img-edge-cover4">
                        <img class="game_bottom_img" src="../../assets/image/1.png" alt="">
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- 联系我们表单区域 -->
        <div class="box">
            <div class="box_title" id="contact2" style="width: 100%; text-align: center;font-size: 28px; margin-bottom: 150px;"><h1>CONTACT US</h1></div>
            <img src="../../assets/image/s1.jpg" style="max-width: 500px; max-height: 333.33px;" alt="">
            <div class="box_right">
                <div class="box_left">
                    <h3 style="font-size: 20px; margin-bottom: 30px;">Santa Jacinta</h3>
                    <p></p>
                    <p style="font-size: 18px;">If you are interested in collaborating with us or would like to learn more information, please contact us by email!</p>
                </div>
                <el-form :model="params" ref="dynamicValidateForm" :rules="rules" label-width="100px" class="demo-dynamic">
                    <el-form-item prop="name" label="Name">
                        <el-input v-model="params.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="title" label="Title">
                        <el-input v-model="params.title"></el-input>
                    </el-form-item>
                    
                    <el-form-item prop="content" label="Content">
                        <el-input v-model="params.content" type="textarea" :autosize="{ minRows: 2, maxRows: 6}"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="Email">
                        <el-input v-model="params.email"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <a href="mailto:santajacinta@hotmail.com?subject=【Guanwang】"><el-button type="primary" @click="submitForm('dynamicValidateForm')">SEND</el-button></a>
                        <el-button @click="resetForm('dynamicValidateForm')">RESET</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="footer_nav">
            <div class="top_nav " style="display: flex; width:70%;">
                <ul class="footer_nav_bottom" style="display: flex;justify-content: space-evenly;background-color: #545c64; list-style: none; width: 80%; line-height: .2604rem;">
                    <li ><router-link :to="$route.name.includes('vip')?'/vip/privacy.html':'/privacy.html'" style="font-size: .0729rem; text-decoration: none; color: #fff;">Privacy Policy</router-link></li>
                    <li ><router-link :to="$route.name.includes('vip')?'/vip/termsofservice.html':'/termsofservice.html'" style="font-size: .0729rem; text-decoration: none; color: #fff;">Terms of Service</router-link></li>
                    <li ><router-link v-if="$route.name.includes('vip')" to='/vip/respongamepolicy.html' style="font-size: .0729rem; text-decoration: none; color: #fff;">Responsible Gameplay Policy</router-link></li>
                    <li ><router-link v-if="$route.name.includes('vip')" to='/vip/gamestate.html' style="font-size: .0729rem; text-decoration: none; color: #fff;">Game Statement</router-link></li>
                    <li ><router-link v-if="$route.name.includes('vip')" to="/vip/sweepsrules.html" style="font-size: .0729rem; text-decoration: none; color: #fff;">Sweeps Rules</router-link></li>
                </ul>
            </div>
            <div class="footer_bottom">
                <span>©</span><p> &nbsp;Santa Jacinta 2024</p> <i class="iconfont icon-facebook"></i><i class="iconfont icon-tuite"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Home",
    data() {
        return {
            activeIndex: '1',
            home_text: 'Crafting adventures through immersive gameplay experiences.',
            about_text:"Santa Jacinta is dedicated to pushing the boundaries of gaming innovation. Our core aim remains the creation of engaging games that also achieve commercial success. Since our inception, we've thrived on principles of empowerment, diligence, and data-driven decisions. Our exceptional team shares a fervent commitment to crafting inclusive gaming experiences. Santa Jacinta remains at the forefront of pioneering the future of gaming, advancing one game at a time.",
            story_text:"Santa Jacinta was forged by the collaboration of three visionaries with a singular goal: to transform the gaming landscape. By melding their varied expertise in business development and game design, they aimed to pioneer a new era of game creation. Their objective? To develop enjoyable games that also achieve commercial success. Since its inception, Santa Jacinta has thrived on the principles of empowerment, diligence, and data-driven decision-making. This ethos has fueled the ongoing growth of our exceptional team, comprised of the most talented and innovative individuals who share an unwavering passion for crafting inclusive gaming experiences for all.",
           

            game1_text:"Discover the royal suite of excitement in REGAL ACES, where the classic game of Blackjack meets the thrilling spins of Slots. Immerse yourself in a uniquely themed adventure with high stakes and grand rewards. Ready to test your strategy and luck in a game designed for kings and queens? Play REGAL ACES now!",
            
            params: {},
            rules: {
                name:[ { required: true, message: 'Name cannot be empty!', trigger: 'blur' },],
                title:[ { required: true, message: 'Title cannot be empty!', trigger: 'blur' },],
                content:[ { required: true, message: 'The message content cannot be empty!', trigger: 'blur' },],
                email: [
                    { required: true, message: 'Please enter your email address', trigger: 'blur' },
                    { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                ],
            },
        }
    },
    mounted(){
        let arr = ["home","about","story","games","contact"];
         arr.map(async item=>{
            await this.scroll(item);
        })
    },
    methods:{
        scroll(id){
            if (id) {
                let node = document.getElementById(id);
                node.addEventListener('click', function(event){
                    event.preventDefault(); // 阻止a标签默认行为
                    let node2 = document.getElementById(`${id}2`);
                    node2.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动到指定节点
                });
            }
        },
        // 发送邮件
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                window.location.href = `mailto:santajacinta@hotmail.com?subject=【Guanwang】&body=${this.params}`;
            } else {
                return false;
            }
            });
        },
        // 重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="scss" scoped>
@import url(../../utils/fontcss.css);
.container {
    width: 100%;
}



// 导航栏区域
.top_nav {
    width: 5.625rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .top_nav_left{
        .top_nav_left_img{
            width: 1.0417rem;
            height: .2604rem;
        }
    }
}

.top_nav_ul{
    display: flex;
    justify-content: space-evenly;
    width: 3.6458rem;
    li{
        list-style: none;
        
        a{
            font-size: .0938rem;
            color: #666;
            line-height: .3646rem;
        }
    }
}
.top_nav_ul{
    li>a:hover{
        color: #000;
    }
}

// home区域
.home{
    width: 100%;
    position: relative;
    .home_img{
        width: 100%;
        height: auto;
        margin-bottom: 4.4271rem;
    }
    .home_text_box{
        width: 100%;
        height: 4.6875rem;
        color: #fff;
        background-color: #1997ed;
        padding: 2.0833rem 0 1.5625rem;
        // 梯形
        clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
        position: absolute;
        top: 4.4271rem;
        left: 0;
        .home_text{
            display: block;
            font-size: .3125rem;
            width: 5.2083rem;
            margin: 0 auto;
        }
    }

}

// about 区域
.about{
    width: 8.8542rem;
    margin: 0 auto 0;
    padding: .1406rem 0;
    display: flex;
    justify-content: space-between;
    .about_left_img{
        width: 4.1667rem;
        height: auto;
    }
    .about_right{
        width: 4.1667rem;
        height: 3.2292rem;
        font-size: .2344rem;
        .about_text_tit{
            color: #333;
            display: flex;
            width: .8854rem;
            border-bottom: .026rem solid #1997ed;
            padding-bottom: .0521rem;
            span{
                width: .0521rem;
                display: block;
                padding-top: .0625rem;
            }
        }
        .about_text{
            width: 3.125rem;
            margin: .2604rem 0 .1563rem;
            font-size: .0938rem;
            color: #858484;
            line-height: .1563rem;
        }
    }
}

// story区域
.story{
    width: 100%;
    margin-top: .5208rem;
    .story_up_img{
        width: 100%;
        height: auto;
        // 梯形
        clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
        margin-bottom: .5208rem;
    }
    .story_body{
        width: 6.7708rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        .story_left{
            width: 4.1667rem;
            height: 3.2292rem;
            font-size: .2344rem;
            .story_text_tit{
                color: #333;
                display: flex;
                width: .8854rem;
                border-bottom: .026rem solid #1997ed;
                padding-bottom: .0521rem;
                span{
                    width: .0521rem;
                    display: block;
                    padding-top: .0625rem;
                }
            }
            .story_text{
                width: 3.125rem;
                margin: .2604rem 0 .1563rem;
                font-size: .0938rem;
                color: #858484;
                line-height: .1563rem;
            }
        }
        .story_right_img{
            width: 2.6563rem;
            height: auto;
        }
    }
}

// the team 样式区域
.the_team_up_img{
    width: 100%;
    height: auto;
    clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    margin: .5208rem 0;
}
.team{
    width: 5.625rem;
    margin: 0 auto 0;
    padding: .1406rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .team_left_img{
        width: 2.6042rem;
        height: auto;
    }
    .team_right{
        width: 2.6563rem;
        height: 3.2292rem;
        font-size: .2344rem;
        .team_text_tit{
            color: #333;
            display: flex;
            width: .8854rem;
            border-bottom: .026rem solid #1997ed;
            padding-bottom: .0521rem;
            span{
                width: .0521rem;
                display: block;
                padding-top: .0625rem;
            }
        }
        .team_text{
            width: 3.125rem;
            margin: .2604rem 0 .1563rem;
            font-size: .0938rem;
            color: #858484;
            line-height: .1563rem;
        }
        .team_2{
            width: 2.7604rem;
            height: auto;
        }
    }
}
.team_down_img{
    display: block;
    margin: .2604rem auto;
    width: 5.625rem;
}
.team_down_text{
    display: flex;
    justify-content: space-evenly;
    div{
        font-size: .0885rem;
        line-height: .1563rem;
        color: #666;
    }
}

.deam_games{
    width: 100%;
    height: auto;
    clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 85%);
}

// games 样式区域
.games{
    width: 100%;
    .games_tit{
        width: 2.6042rem;
        margin: .2604rem auto;
        padding-bottom: .2813rem;
        font-size: .2344rem;
        line-height: .2344rem;
        display: flex;
        justify-content: center;
        span{
            width: .0521rem;
            display: block;
            padding-top: .0625rem;
        }
    }
    .games_show{
        width: 100%;
        .game_show{
            width: 100%;
            // height: 2.8646rem;
            padding: .7813rem 0;
            margin-bottom: -190px;
            background-color: #7044ad;
            clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 90%);
            position: relative;
            .game{
                display: flex;
                justify-content: center;
                img{
                    width: 1.5625rem;
                    height: auto;
                    margin-right: .0781rem;
                    border-radius: .3802rem;
                }
                .game_text{
                    width: 4.0625rem;
                    font-size: .1042rem;
                    color: #fff;
                    line-height: .1823rem;
                    font-family: 'Lemon-Regular';
                    padding-top: 30px;
                }
            }
            .img-edge-cover {
                width: 100%;
                position: relative;
            }
            .img-edge-cover:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                box-shadow:0 0 15px 90px #7044ad  inset;
            }
            .img-edge-cover2:after {
                box-shadow:0 0 15px 90px #1997ee  inset;
            }
            .img-edge-cover3:after {
                box-shadow:0 0 15px 90px #153771  inset;
            }
            .img-edge-cover4:after {
                box-shadow:0 0 5px 5px #fff  inset;
            }
            .img-edge-cover img{
                width: 100%;
                height: auto;
                display: block;
                margin-bottom: 20px;
            }
            .game_bottom_img{
                width: 100%;
                height: auto;
                // filter: blur(0px) brightness(90%);
                // backdrop-filter: blur(10px);
                // box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.7); /* 模糊的大小和颜色 */
            }
        }
        .gamebgcolor2{
            background-color: #1997ee;
        }
        .gamebgcolor3{
            background-color: #153771;
        }
        .gamebgcolor4{
            clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);
            // background-color: #9ceae5;
            background-color: #fff;
            .game{
                .game_text{
                    color: #666;
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

// news区域

.news{
    width: 100%;
    .news_head{
        font-size: 0.2344rem;
        color: #333;
        display: flex;
        width: 5.2083rem;
        margin: 0 auto;
        border-bottom: .026rem solid #1997ed;
        padding-bottom: .0521rem;
        span{
            width: .0521rem;
            display: block;
            padding-top: .0625rem;
        }
    }
    .news_container{
        width: 5.2083rem;
        margin: 0 auto;
        .news_tit{
            width: 4.6875rem;
            font-size: .2083rem;
            font-weight: bold;
            line-height: 50px;
            color: #333;
            margin: .2604rem 0 .1563rem;
        }
        .news_time{
            font-size: .1042rem;
            line-height: 20px;
            color: #666;
            margin: .1563rem 0;
        }
        .news_text{
            font-size: .1042rem;
            line-height: .1823rem;
            color: #666;
            margin: .1563rem 0 .3646rem;
        }
    }
}
.news_bottom_img{
    width: 100%;
    height: auto;
    clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%);
}

.footer_nav_bottom{
    width: 100% !important;
    li{
        width: 100%;
        text-align: center;
    }
}
// 导航栏样式
// 导航栏单个选项样式-选中
.footer_nav{
    width: 100%;
    background-color: #545c64;
    margin: 0 auto;
    .footer_bottom{
        line-height: .2604rem;
        background-color: #000;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            font-size: .0833rem;
            line-height: 44px;
            color: #666;
        }
        p{
            font-size: .0833rem;
            line-height: 44px;
            color: #666;
            font-weight: bold;
            margin-right: 2.6042rem;
        }
        .iconfont{
            color: #666;
            font-size: .1563rem;
        }
    }
}
.box {
    width: 100%;
    margin: 0 auto;
    padding: 320px .7813rem 150px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // background-color: #9ceae5;
    background-color: #fff;
    .box_left{
        width: 500px;
        margin-bottom: 30px;
        p{
            color: #666;
        }
    }
    .box_right{
        width: 500px;
    }
}

@media (max-width: 770px) {

    // games样式区域
    // .games{
    //     .games_tit{
    //      span{
    //      }
    //  }
    //  .game_show{
    //  }
    // }
}
</style>